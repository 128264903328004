export const getCurrencyFormat = (
  currency: string = "USD",
  options: Intl.NumberFormatOptions = {},
) => {
  try {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency,
      maximumFractionDigits: 2,
      ...options,
    });
  } catch {
    const rootFormatter = new Intl.NumberFormat("en-US", {
      maximumFractionDigits: 2,
      style: "currency",
      currency: "BTC",
    });

    return {
      format: (x: number | bigint | undefined) =>
        rootFormatter
          .formatToParts(x)
          .map((part) => (part.type === "currency" ? currency : part.value))
          .join(""),
      formatToParts: (x: number | bigint | undefined) =>
        rootFormatter.formatToParts(x).map((part) =>
          part.type === "currency"
            ? ({
                ...part,
                value: currency,
              } as Intl.NumberFormatPart)
            : part,
        ),
      resolvedOptions: rootFormatter.resolvedOptions,
    };
  }
};
